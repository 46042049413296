@tailwind base;
@tailwind components;
@tailwind utilities;

/* https://fonts.google.com/noto/specimen/Noto+Sans+Lao */
@font-face {
    font-family: "NotoSanLight";
    src: local("NotoSanLight"),
        url("../src/assets/font/NotoSansLao-Medium.ttf") format("truetype");
}

@layer components {
    .selected {
        color: #fff;
        background-color: #3b82f6 !important;
    }
}

* {
    font-family: "NotoSanLight", sans-serif !important;
}

.logoBrand {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modelCard {
    display: flex;
    flex-direction: column;
    background: white;
    width: 400px;
    height: 240px;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    border-width: 1px;
}

.modelCardUser {
    display: flex;
    flex-direction: column;
    background: white;
    width: 400px;
    height: auto;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    border-width: 1px;
}

.profile-name {
    display: none !important;
}

@media only screen and (max-width: 768px) {
    .modelCard {
        width: 330px;
    }

    .modelCardUser {
        width: 330px;
    }

    .profile-name {
        display: block !important;
    }
}

.react-daterange-picker input::placeholder {
    color: white;
}

.modalLabel {
    text-align: left;
    font-size: 16px;
    margin-bottom: 5px;
}

.modalInput {
    padding: 14px 4px;
    border: 1px solid #36b99a3d;
    border-radius: 4px;
    margin-bottom: 15px;
}

.modalBtn {
    background: #26B899;
    color: #fff;
    padding: 10px 4px;
    font-size: 18px;
    font-weight: 600;
}

.dropdown-label {
    background-color: #fff;
}

.dropdown-user {
    margin: 0px 0px 20px 0px !important;
}

.dropdown-user select {
    border: 1px solid #36b99a3d !important;
}

/* Hide Button Clear Filter Date Data */
button.react-daterange-picker__clear-button.react-daterange-picker__button {
    display: none;
}

/* Style Filter Start End Date */
.react-daterange-picker__wrapper {
    padding: 4px 10px;
    border-radius: 2px;
    background: #36b99a;
    color: #fff;
    border: none !important;
}

.react-calendar__tile--active {
    background: #36b99a !important;
}

/* Style DataGrid */
.MuiDataGrid-main.css-204u17-MuiDataGrid-main {
    border: 1px solid rgb(224 224 224) !important;
    border-radius: 4px !important;
}

.MuiDataGrid-main.css-opb0c2 {
    border: 1px solid rgb(224 224 224) !important;
    border-radius: 4px !important;
}

.css-1j3e8e3-MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: start !important;
    padding: 0 10px !important;
}

.css-1j3e8e3-MuiDataGrid-root .MuiDataGrid-cell--textRight {
    justify-content: start !important;
}

/* .MuiDataGrid-toolbarContainer.css-ypofq1-MuiDataGrid-toolbarContainer {
    width: 100%;
    justify-content: end;
    padding: 0;
} */